import { Home, NotFound, Article } from '../pages'

export default {
    index: {
        title: 'Home',
        path: '/',
        component: Home,
        exact: true,
    },
    article: {
        title: 'Article',
        path: '/articles/:id',
        component: Article,
    },
    notFound: {
        title: 'Not Found',
        path: '/*',
        component: NotFound,
    },
}