import logo from '../../assets/logo.svg'
import menu from '../../assets/menu.svg'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import withStyles from 'react-jss'
import styles from './Navigation.styles'
import { HashLink as Link } from 'react-router-hash-link'
import Scroll from 'smooth-scroll'

const Navigation = ({ classes }) => {
    const [open, setOpen] = useState(false)

    const linksClasses = classNames({
        [classes.links]: true,
        [classes.linksOpen]: open,
    })

    const handleToggle = open => {
        document.documentElement.style.overflow = open ? 'hidden' : 'auto'
        setOpen(open)
    }

    useEffect(() => {
        window.addEventListener('resize', () => handleToggle(false))
        let scroll = new Scroll('a[href*="#"]', {
            offset: () => 100
        });
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', () => handleToggle(false))
        }
    })

    return <div className={classes.navigation}>
        <div className={classes.container}>
            <div className={classes.branding}>
                <Link to="/"><img src={logo} alt="Caton Sports Association" /></Link>
            </div>
            <button className={classes.toggle} onClick={() => handleToggle(!open)}>
                <img src={menu} />
            </button>
            <nav className={linksClasses}>
                <Link className={classes.link} to="/#about">About</Link>
                <Link className={classes.link} to="/#fixtures">Fixtures</Link>
                <Link className={classes.link} to="/#league">League</Link>
                <Link className={classes.link} to="/#news">News</Link>
                <Link className={classes.link} to="/#contact">Contact</Link>
                <Link className={classes.link} to="/#sponsors">Sponsors</Link>
            </nav>
        </div>
    </div>
}

export default withStyles(styles)(Navigation)