import React from 'react'
import withStyles from 'react-jss'
import styles from './Article.styles'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

const truncate = (string, limit) => string.length > limit ? `${string.substring(0, limit)}...` : string

const Article = ({ id, title, content, publishedAt, classes }) =>
    <li className={classes.article}>
        <div>
            <div className={classes.title}>{title}</div>
            <div
                className={classes.date}>{format(new Date(publishedAt), 'do MMMM yyyy')}</div>
            <p className={classes.content}>{truncate(content.text, 80)}</p>
        </div>
        <div>
            <Link className={classes.link} to={`/articles/${id}`}>Read More &rarr;</Link>
        </div>
    </li>

export default withStyles(styles)(Article)