import React, { useEffect, useState } from 'react'
import withStyles from 'react-jss'
import styles from './Hero.styles'
import PropTypes from 'prop-types'
import logo from '../../assets/logo.svg'
import menu from '../../assets/menu.svg'
import classNames from 'classnames'
import Scroll from 'smooth-scroll'


const Hero = ({ classes }) => {
    const [open, setOpen] = useState(false)

    const linksClasses = classNames({
        [classes.links]: true,
        [classes.linksOpen]: open,
    })

    const handleToggle = open => {
        document.documentElement.style.overflow = open ? 'hidden' : 'auto'
        setOpen(open)
    }

    useEffect(() => {
        window.addEventListener('resize', () => handleToggle(false))
        let scroll = new Scroll('a[href*="#"]', {
            offset: () => 100
        });
    }, [])

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', () => handleToggle(false))
        }
    })

    return <div className={classes.hero}>
        <div className={classes.navigation}>
            <div className={classes.container}>
                <div className={classes.branding}>
                    <img src={logo} alt="Caton Sports Association" />
                </div>
                <button className={classes.toggle} onClick={() => handleToggle(!open)}>
                    <img src={menu} />
                </button>
                <nav className={linksClasses}>
                    <a className={classes.link} href="#about">About</a>
                    <a className={classes.link} href="#fixtures">Fixtures</a>
                    <a className={classes.link} href="#league">League</a>
                    <a className={classes.link} href="#news">News</a>
                    <a className={classes.link} href="#contact">Contact</a>
                    <a className={classes.link} href="#sponsors">Sponsors</a>
                </nav>
            </div>
        </div>
        <div className={classes.content}>
            <div className={classes.container}>
                <h1 className={classes.title}>Sports in the Lune Valley Area</h1>
                <p className={classes.paragraph}>Caton Sports Association promote and encourage involvement in sports in
                    the
                    Lune Valley Area. Check out our
                    excellent facilities or find out more about what we do below.</p>
            </div>
        </div>
    </div>
}

Hero.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Hero)