import React from 'react'
import withStyles from 'react-jss'
import styles from './Fixtures.styles'
import { Fixture } from '../Fixture'
import PropTypes from 'prop-types'

const Fixtures = ({ title, classes, fixtures, hasNextPage, loading, error, fetchMore }) => {
    const handleFetchMore = () => {
        fetchMore({
            variables: {
                offset: fixtures.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                    fixturesConnection: {
                        ...fetchMoreResult.fixturesConnection,
                        edges: [
                            ...prev.fixturesConnection.edges,
                            ...fetchMoreResult.fixturesConnection.edges,
                        ],
                    },
                }

            },
        })
    }

    return <div className={classes.fixtures}>
        <div className={classes.container}>
            <div className={classes.introduction}>
                <h2 id="fixtures" className={classes.title}>{title} Fixtures</h2>
                <p className={classes.body}>
                    Here's what's coming up for {title}
                </p>
            </div>
            <div className={classes.content}>
                {!loading && !error && !fixtures.length ? <div className={classes.none}>We don't have any fixtures lined up at the moment. Please check back again soon.</div> : null}
                <ul className={classes.list}>
                    {!loading && !error && fixtures.map(({ id, title, homeTeam, awayTeam, date, location, homeMatch, homeScore, awayScore }) =>
                        <Fixture
                            key={id}
                            id={id}
                            title={title}
                            homeTeam={homeTeam}
                            awayTeam={awayTeam}
                            homeScore={homeScore}
                            awayScore={awayScore}
                            date={date}
                            homeMatch={homeMatch}
                            location={location} />)}
                </ul>
                {!loading && !error && fixtures.length ? <div className={classes.buttons}>
                    <button className={classes.loadButton} onClick={handleFetchMore}
                            disabled={!hasNextPage}>Show more fixtures
                    </button>
                </div> : null}
            </div>
        </div>
    </div>
}

Fixtures.propTypes = {
    title: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    fixtures: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        homeTeam: PropTypes.string.isRequired,
        awayTeam: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        homeMatch: PropTypes.bool.isRequired,
    })),
    hasNextPage: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchMore: PropTypes.func.isRequired,
}

export default withStyles(styles)(Fixtures)