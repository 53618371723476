export default {
    fixture: {
        composes: 'Fixture',
        backgroundColor: '#3F4E5C',
        borderRadius: '1em',
        padding: '1.5em',
        margin: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    away: {
        backgroundColor: '#333435',

        '& $badge': {
            backgroundColor: '#676768',
        },
    },
    details: {
        marginTop: '1em',
    },
    title: {
        color: '#fff',
        fontSize: '1.1em',
        fontWeight: 700,
        lineHeight: 1.2,
    },
    date: {
        fontSize: '0.9em',
        color: '#fff',
        fontWeight: 600,
        marginBottom: 0,
    },
    content: {
        fontSize: '0.85em',
        color: '#fff',
        margin: 0,
    },
    badge: {
        display: 'inline-block',
        fontSize: '0.9em',
        fontWeight: 600,
        marginTop: '0.8em',
        padding: '0.5em 1em',
        borderRadius: '0.6em',
        backgroundColor: '#218CC7',
        color: '#fff',
    },
    score: {
        color: '#fff',
        marginLeft: '1rem',
        fontWeight: 700
    }
}