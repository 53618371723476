import introduction from '../../assets/introduction.jpg'

export default ({ breakpoints }) => ({
    introduction: {
        composes: 'Introduction',
        backgroundColor: '#218CC7',

        '& $container': {
            flexDirection: 'column',
            alignItems: 'stretch',

            [breakpoints.sm]: {
                flexDirection: 'row',
            },
        },
    },
    block: {
        composes: 'Introduction__block',
        flexBasis: 'calc(33.333% - 2em)',
        padding: '1em',

        [breakpoints.sm]: {
            padding: '5em 1em',
        }
    },
    image: {
        extend: 'block',
        backgroundImage: `url(${introduction})`,
        backgroundSize: 'cover',
        display: 'none',

        [breakpoints.sm]: {
            display: 'block'
        }
    },
    title: {
        margin: 0,
        marginBottom: '0.5em',
        color: '#fff',
    },
    paragraph: {
        margin: 0,
        color: '#F4F4F4',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80em',
        maxWidth: 'calc(100% - 2em)',
        margin: '0 auto',
        padding: '1em',

        [breakpoints.sm]: {
            flexDirection: 'row',
            maxWidth: 'calc(100% - 3em)',
            padding: '0 1.5em',
        },
    },
})