import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { routes } from './router'
import { Page, Footer } from './components'
import styles from './App.styles'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'

const App = ({ classes }) => {
    return <div className={classes.app}>
        <Router>
            <div>
                <Switch>
                    {Object.values(routes).map(({ path, exact = false, component, title }) =>
                        <Route
                            key={path}
                            exact={exact}
                            path={path}
                            render={
                                props => <Page {...props} component={component} title={title} />
                            }
                        />)
                    }
                </Switch>
            </div>
            <Footer />
        </Router>
    </div>
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App)
