import React from 'react'
import withStyles from 'react-jss'
import styles from './Articles.styles'
import { Article } from '../Article'

const Articles = ({ classes, articles, total, loading, error, fetchMore }) => {
    const handleFetchMore = () => fetchMore({
        variables: {
            offset: articles.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev
            return Object.assign({}, prev, {
                articles: [...prev.articles, ...fetchMoreResult.articles],
            })
        },
    })

    return <div className={classes.articles}>
        <div className={classes.container}>
            <div className={classes.introduction}>
                <h2 id="news" className={classes.title}>News</h2>
                <p className={classes.body}>
                    Stay up to date with the sports association by checking out some of our
                    recent articles.
                </p>
            </div>
            <div className={classes.content}>
                {!loading && !error && !articles.length ? <div className={classes.none}>No news articles found. Please check back again soon.</div> : null}
                <ul className={classes.list}>
                    {!loading && !error && articles.map(({ id, title, publishedAt, content }) =>
                        <Article key={id} id={id} title={title} content={content} publishedAt={publishedAt} />)}
                </ul>
                <div className={classes.buttons}>
                    <button className={classes.loadButton} onClick={handleFetchMore}
                            disabled={total <= articles?.length}>Show more articles
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default withStyles(styles)(Articles)