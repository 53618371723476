import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { ThemeProvider } from 'react-jss'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import theme from './theme'
import {QueryClient, QueryClientProvider} from "react-query";

const client = new ApolloClient({
    uri: 'https://api-eu-central-1.graphcms.com/v2/ckgo3hqze25rw01wfg5uahfx7/master',
    cache: new InMemoryCache(),
})

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={new QueryClient()}>
                <ApolloProvider client={client}>
                    <GoogleReCaptchaProvider reCaptchaKey="6LfIOeAZAAAAAErrkkhGrjoD8i_amY-cboioiSCL">
                        <App />
                    </GoogleReCaptchaProvider>
                </ApolloProvider>
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)