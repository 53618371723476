import React from 'react'
import { Hero, Introduction, Articles, Fixtures, ContactForm, League, Results } from '../../components'
import { useQuery, gql } from '@apollo/client'
import formatISO from 'date-fns/formatISO'
import startOfDay from 'date-fns/startOfDay'
import {Donations} from "../../components/Donations";

const Home = () => {
    const articles = useQuery(gql`
        query GetArticles($offset: Int, $limit: Int, $after: String) {
            articles(orderBy: createdAt_DESC, first: $limit, skip: $offset, after: $after) {
                id
                title
                content {
                    text
                }
                updatedAt
                publishedAt
            }
            articlesConnection {
                aggregate {
                    count
                }
            }
        }
    `, { variables: { offset: 0, limit: 3 } })

    const unitedFixtures = useQuery(gql`
        query GetFixtures($offset: Int, $limit: Int, $after: String, $date: DateTime) {
            fixturesConnection(orderBy: date_ASC, first: $limit, skip: $offset, after: $after, where: { 
                OR: [
                  { homeTeam: "Caton United" },
                  { awayTeam: "Caton United" }
                ],
                date_gte: $date 
            }) {
                edges {
                    node {
                        id
                        homeTeam
                        awayTeam
                        location
                        date
                        homeMatch
                        homeScore
                        awayScore
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    `, {
        variables: {
            offset: 0, limit: 3, date: formatISO(startOfDay(new Date())),
        },
    })

    const coltsFixtures = useQuery(gql`
        query GetFixtures($offset: Int, $limit: Int, $after: String, $date: DateTime) {
            fixturesConnection(orderBy: date_ASC, first: $limit, skip: $offset, after: $after, where: { 
                    OR: [
                      { homeTeam: "Caton Colts" },
                      { awayTeam: "Caton Colts" }
                    ],
                    date_gte: $date
                }) {
                edges {
                    node {
                        id
                        homeTeam
                        awayTeam
                        location
                        date
                        homeMatch
                        homeScore
                        awayScore
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    `, {
        variables: {
            offset: 0, limit: 3, date: formatISO(startOfDay(new Date())),
        },
    })

    const results = useQuery(gql`
        query GetResults($offset: Int, $limit: Int, $after: String, $date: DateTime) {
            fixturesConnection(orderBy: date_DESC, first: $limit, skip: $offset, after: $after, where: { date_lte: $date, homeScore_gte: 0 }) {
                edges {
                    node {
                        id
                        homeTeam
                        awayTeam
                        location
                        date
                        homeMatch
                        homeScore
                        awayScore
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    `, {
        variables: {
            offset: 0, limit: 3, date: formatISO(startOfDay((new Date()))),
        },
    })

    return <>
        <Hero />
        <Introduction />
        <Fixtures
            title="Caton United"
            fixtures={unitedFixtures.data?.fixturesConnection.edges.map(edge => edge.node)}
            loading={unitedFixtures.loading} error={unitedFixtures.error}
            fetchMore={unitedFixtures.fetchMore}
            hasNextPage={unitedFixtures.data?.fixturesConnection.pageInfo.hasNextPage} />
        <Fixtures
            title="Caton Colts"
            fixtures={coltsFixtures.data?.fixturesConnection.edges.map(edge => edge.node)}
            loading={coltsFixtures.loading} error={coltsFixtures.error}
            fetchMore={coltsFixtures.fetchMore}
            hasNextPage={coltsFixtures.data?.fixturesConnection.pageInfo.hasNextPage} />
        <Donations/>
        <Results
            fixtures={results.data?.fixturesConnection.edges.map(edge => edge.node)}
            loading={results.loading} error={results.error}
            fetchMore={results.fetchMore}
            hasNextPage={results.data?.fixturesConnection.pageInfo.hasNextPage} />
        <League />
        <Articles
            articles={articles.data?.articles} loading={articles.loading} error={articles.error}
            fetchMore={articles.fetchMore}
            total={articles.data?.articlesConnection.aggregate.count} />
        <ContactForm />
    </>
}

export default Home