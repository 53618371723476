import React from 'react'
import withStyles from 'react-jss'
import styles from './Fixture.styles'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Fixture = ({ homeTeam, awayTeam, location, date, homeMatch, homeScore, awayScore, classes }) => {
    const fixtureClasses = classNames({
        [classes.fixture]: true,
        [classes.away]: !homeMatch,
    })

    return <li className={fixtureClasses}>
        <div>
            <div className={classes.title}>{homeTeam} vs {awayTeam}</div>
            <div className={classes.details}>
                <div className={classes.date}>{format(new Date(date), 'do MMMM yyyy HH:mm')}</div>
                <p className={classes.content}>{location}</p>
                <span className={classes.badge}>{homeMatch ? 'Home' : 'Away'}</span>
                {(homeScore !== null || awayScore !== null) && <span className={classes.score}>{homeScore || 0} - {awayScore || 0}</span>}
            </div>
        </div>
    </li>
}

Fixture.propTypes = {
    id: PropTypes.string.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    homeMatch: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    homeScore: PropTypes.number,
    awayScore: PropTypes.number,
}

Fixture.defaultProps = {
    homeScore: null,
    awayScore: null,
}

export default withStyles(styles)(Fixture)