export default ({ breakpoints }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        padding: '0 1.5em',
        maxWidth: 'calc(100% - 3em)',

        '& article': {
            maxWidth: '50em',
        },

        [breakpoints.sm]: {
            width: '80em',
        },
    },
    titles: {
        marginBottom: '1em',
        paddingBottom: '1em',
    },
    title: {
        color: '#222',
        fontSize: '2.5em',
        marginBottom: '0.5em',
    },
    back: {
        textDecoration: 'none',
        marginTop: '2em',
        display: 'inline-block',
        backgroundColor: '#eee',
        padding: '0.75em 1em',
        color: '#333',
        borderRadius: '1em',
        fontWeight: 500,
        fontSize: '0.9em',

        '&:hover': {
            backgroundColor: '#ddd',
            transition: '150ms linear background-color',
        },
    },
    date: {
        margin: 0,
        fontSize: '0.9em',
        fontWeight: 400,
    },
    socials: {
        marginTop: '1em'
    },
    loader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2em'
    }
})