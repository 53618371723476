import React from 'react'
import withStyles from 'react-jss'
import styles from './Introduction.styles'

const Introduction = ({ classes }) => <div className={classes.introduction}>
    <div className={classes.container}>
        <div className={classes.block}>
            <h2 id="about" className={classes.title}>Our Vision</h2>
            <p className={classes.paragraph}>... is to be an excellent independent sustainable amateur Sports
                Association Club and a leading provider
                of sports facilities in the Lune Valley area, recognised as a valued contributor to the local
                community.</p>
        </div>
        <div className={classes.block}>
            <h2 className={classes.title}>Our Mission</h2>
            <p className={classes.paragraph}>... is to be a high quality, well run club, offering opportunities for all
                ages, genders and levels of
                ability; embracing core values of Sports by creating an environment and culture that promotes teamwork,
                discipline, respect and enjoyment.</p>
        </div>
        <div className={classes.image}>

        </div>
    </div>
</div>

export default withStyles(styles)(Introduction)