import React from 'react'
import withStyles from 'react-jss'
import styles from './Donations.styles'

const Donations = ({ classes }) => {
    return <div className={classes.articles}>
        <div className={classes.container}>
            <div className={classes.introduction}>
                <h2 id="news" className={classes.title}>Monthly Draw</h2>
                <p className={classes.body}>
                    Enter our monthly draw for the chance to win great cash prizes.
                </p>
            </div>
            <div className={classes.content}>
                <iframe
                    src="https://donorbox.org/embed/caton-sports-association?default_interval=m"
                    name="donorbox"
                    allowpaymentrequest="allowpaymentrequest"
                    seamless="seamless"
                    style={{ width: '400px', maxWidth: '100%', border: 'none' }}
                />
            </div>
        </div>
    </div>
}

export default withStyles(styles)(Donations)