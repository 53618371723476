export default ({ breakpoints }) => ({
    form: {
        display: 'flex',
        direction: 'column',
        padding: '1em',
        width: 'calc(100% - 2em)',

        [breakpoints.sm]: {
            width: 'calc(50% - 2em)',
        },

        [breakpoints.md]: {
            width: 'calc(33.333% - 2em)',
        },

        '& $input, $textarea': {
            marginBottom: '0.5em',
        },

        '& h2': {
            marginTop: 0,
        },
    },
    contact: {
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80em',
        maxWidth: 'calc(100% - 2em)',
        margin: '0 auto',

        [breakpoints.sm]: {
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: 'calc(100% - 3em)',
            padding: '2em 1.5em',
        },
    },
    input: {
        fontFamily: 'inherit',
        fontSize: '1em',
        padding: '0.5em',
        borderRadius: '0.3em',
        border: '1px solid #ddd',

        '&:focus': {
            border: '1px solid #333',
            transition: '150ms linear border',
        },
    },
    textarea: {
        extend: 'input',
        resize: 'none',
    },
    errored: {
        borderColor: 'red',
    },
    button: {
        padding: '0.75em 1em',
        fontFamily: 'inherit',
        fontSize: '1em',
        fontWeight: 500,
        backgroundColor: '#218CC7',
        borderRadius: '1em',
        color: '#fff',
        border: 'none',
        display: 'inline-block',
    },
    mapContainer: {
        border: '1px solid #ddd',
        borderRadius: '1em',
        width: '100%',
        overflow: 'hidden',

        [breakpoints.sm]: {
            display: 'flex',
            width: '50%',
        },

        [breakpoints.md]: {
            width: '66.666%',
        },
    },
    info: {
        width: '100%',
        padding: '1em',

        [breakpoints.sm]: {
            width: 'calc(33.333% - 2em)',
        },
    },
    map: {
        width: '100%',
        height: '25em',

        [breakpoints.sm]: {
            width: '66.666%',
        },
    },
    google: {
        fontSize: '0.75em',
        color: '#999',
        marginBottom: '2em',

        '& a': {
            color: '#666',
        },
    },
    error: {
        margin: '0 0 1em',
        padding: 0,
        fontSize: '0.75em',
        color: 'red',
    },
    count: {
        fontSize: '0.75em',
        color: '#666',
        textAlign: 'right',
        padding: '0.2em 0 1em',
    },
    countErrored: {
        color: 'red',
    },
    sent: {
        padding: '0.75em 1em',
        fontFamily: 'inherit',
        fontSize: '1em',
        fontWeight: 500,
        backgroundColor: '#218CC7',
        borderRadius: '1em',
        color: '#fff',
    },
    facebook: {
        marginTop: '1em',
        height: '2em',
        width: '2em',
        padding: '0.5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: '1em',

        '&:hover': {
            backgroundColor: '#eee',
            transition: '150ms linear background-color',
        },

        '& img': {
            display: 'block',
            height: '70%',
            width: 'auto',
        },
    },
})