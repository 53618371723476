export default {
    article: {
        composes: 'Article',
        backgroundColor: '#E2ECF2',
        borderRadius: '1em',
        padding: '1.5em',
        margin: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        color: '#39425e',
        fontSize: '1.25em',
        fontWeight: 600
    },
    date: {
        color: '#39425e',
        fontWeight: 500
    },
    content: {
        color: '#4b5575'
    },
    link: {
        marginTop: '0.5em',
        display: 'inline-block',
        backgroundColor: '#218CC7',
        padding: '0.75em 1em',
        color: '#fff',
        borderRadius: '1em',
        fontWeight: 500,
        textDecoration: 'none',
        fontSize: '0.9em',

        '&:hover': {
            backgroundColor: '#17618a',
            transition: '100ms linear all'
        }
    },
}