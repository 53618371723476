export default ({ breakpoints }) => ({
    branding: {
        padding: '0.25em 0',

        '& img': {
            display: 'block',
            maxHeight: '5em',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        padding: '0 1.5em',
        maxWidth: 'calc(100% - 3em)',

        [breakpoints.sm]: {
            width: '80em',
        },
    },
    toggle: {
        background: 'none',
        border: 'none',

        [breakpoints.md]: {
            display: 'none',
        },

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.1)',
            outline: 'none',
        },

        '& img': {
            display: 'block',
            width: '2em',
            padding: '2em',
            height: '2em',
        },
    },
    navigation: {
        composes: 'Navigation',
        width: '100%',
        backgroundColor: '#282F3E',

        [breakpoints.sm]: {
            animation: '$fadeIn',
            animationDuration: '1000ms',
        },

        '& $container': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0',
            maxWidth: '100%',

            [breakpoints.md]: {
                padding: '0 1.5em',
                maxWidth: 'calc(100% - 3em)',
            },
        },

        '& $links': {
            position: 'absolute',
            top: '5.5em',
            width: '100%',
            height: 'calc(100vh - 5.5em)',
            left: 0,
            backgroundColor: '#1C212D',
            display: 'none',
            flexDirection: 'column',
            zIndex: 10,

            [breakpoints.md]: {
                position: 'static',
                height: 'auto',
                background: 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
            },
        },

        '& $linksOpen': {
            display: 'flex',
        },
    },
    links: {},
    linksOpen: {},
    link: {
        display: 'inline-block',
        composes: 'Navigation__link',
        textDecoration: 'none',
        color: '#fff',
        padding: '1.5em 1em',
        fontWeight: 500,

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
        },
    },
})