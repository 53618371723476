export default ({ breakpoints }) => ({
    footer: {
        backgroundColor: '#1C212D',
        marginTop: '2em',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        padding: '1.5em',
        maxWidth: 'calc(100% - 3em)',

        [breakpoints.sm]: {
            width: '80em',
        },
    },
    introduction: {
        [breakpoints.sm]: {
            width: '25%',

            order: 1,
            flexGrow: 0,
            flexShrink: 0,
            paddingRight: '1em',
        },
    },
    content: {
        [breakpoints.sm]: {
            order: 0,
            width: '75%',
        },
    },
    title: {
        color: '#fff',
        fontSize: '2em',
        marginBottom: '0.25em',
    },
    body: {
        color: '#fff',
    },
    links: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
    },
    link: {
        padding: '1em',
        width: 'calc(100% - 2em)',
        borderRadius: '0.3em',

        [breakpoints.sm]: {
            padding: '1em',
            margin: '0.5em',
            width: 'calc(33.333% - 3em)',
        },

        '& a': {
            textDecoration: 'none',
        },

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            transition: '150ms linear all',
        },
    },
    sponsors: {
        [breakpoints.sm]: {
            display: 'flex',
            alignItems: 'center',
        },
    },
    sponsorName: {
        color: '#fff',
        fontWeight: 600,
    },
    sponsorCategory: {
        color: '#4EB7F1',
        fontSize: '0.9em',
    },
    copyright: {
        fontSize: '0.9em',
        color: '#acacac',
    },
    end: {
        backgroundColor: '#151922',
    },
})