export default {
    '@global': {
        'html, body, #root': {
            height: '100%',
            margin: 0,
            padding: 0,
        },
        body: {
            fontSize: '16px',
            fontFamily: '"Inter", sans-serif',
            lineHeight: 1.4,
            color: '#555',
        },
        '.grecaptcha-badge': {
            visibility: 'hidden',
        },
    },
    app: {
        composes: 'App',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
}