import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import axios from 'axios'
import { Formik } from 'formik'
import withStyles from 'react-jss'
import styles from './ContactForm.styles'
import { isEmail } from 'validator'
import classNames from 'classnames'
import facebook from '../../assets/facebook.svg'

const ContactForm = ({ classes }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [sent, setSent] = useState(false)

    const sendEmail = async (values, { setSubmitting, resetForm }) => {
        const token = await executeRecaptcha()
        await axios.post('https://europe-west2-caton-sports-association.cloudfunctions.net/sendEmail', {
            name: values.name,
            email: values.email,
            message: values.message,
            token,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: status => [200, 400, 500].includes(status),
        }).then((res) => {
            if (res.data.success) {
                setSent(true)
            }
        }).catch((err) => {
            console.log(err)
        })
            .finally(() => {
                resetForm()
                setSubmitting(false)
            })
    }

    const validate = (values) => {
        let errors = {}

        if (values.name.length === 0 || values.name.length > 255) {
            errors.name = 'Please provide a valid name.'
        }

        if (!isEmail(values.email)) {
            errors.email = 'Please provide a valid email address.'
        }

        if (values.message.length === 0 || values.message.length > 1000) {
            errors.message = 'Please provide a valid message. It must not be longer than 1000 characters.'
        }

        return errors
    }

    const initialValues = {
        name: '',
        email: '',
        message: '',
    }

    return <div className={classes.contact}>
        <div className={classes.container}>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={sendEmail}>
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      touched,
                      isSubmitting,
                  }) =>
                    <form
                        className={classes.form} style={{ display: 'flex', flexDirection: 'column' }}
                        onSubmit={handleSubmit}>
                        <h2 id="contact">Contact Us</h2>
                        <input
                            className={classNames({
                                [classes.input]: true,
                                [classes.errored]: errors.name && touched.name,
                            })}
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            disabled={sent} />
                        {errors.name && touched.name && <p className={classes.error}>{errors.name}</p>}
                        <input
                            className={classNames({
                                [classes.input]: true,
                                [classes.errored]: errors.email && touched.email,
                            })}
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Email Address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            disabled={sent} />
                        {errors.email && touched.email && <p className={classes.error}>{errors.email}</p>}
                        <textarea
                            className={classNames({
                                [classes.textarea]: true,
                                [classes.errored]: errors.message && touched.message,
                            })}
                            id="message"
                            name="message"
                            placeholder="Message"
                            cols="30"
                            rows="10"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.message}
                            disabled={sent} />
                        <div className={classNames({
                            [classes.count]: true,
                            [classes.countErrored]: values.message.length > 1000,
                        })}>{values.message.length}/1000
                        </div>
                        {errors.message && touched.message && <p className={classes.error}>{errors.message}</p>}

                        <div className={classes.google}>
                            This site is protected by reCAPTCHA and the Google{' '}
                            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </div>
                        <div>
                            {sent ?
                                <div className={classes.sent}>Your message has been sent. We'll be in touch as soon as
                                    possible.</div> :
                                <button className={classes.button} type="submit"
                                        disabled={isSubmitting}>Send Message</button>}

                        </div>
                    </form>
                }
            </Formik>
            <div className={classes.mapContainer}>
                <div className={classes.info}>
                    <strong>Caton Sports Field</strong><br />
                    2 Hornby Road<br />
                    Caton<br />
                    Lancaster<br />
                    LA2 9QS
                    <div>
                        <a href="https://www.facebook.com/pages/category/Sports-Club/Caton-Sports-Association-438111333237409/"
                           className={classes.facebook} target="_blank"><img src={facebook} alt="Facebook" /></a>
                    </div>
                </div>
                <div className={classes.map}>
                    <LoadScript googleMapsApiKey="AIzaSyBnZl0L41l4z-N18kE40Hea4A3o7ktugKU">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            center={{ lat: 54.075810, lng: -2.716832 }}
                            zoom={17}
                        >
                            <Marker position={{ lat: 54.075810, lng: -2.716832 }} />
                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>
        </div>
    </div>
}

export default withStyles(styles)(ContactForm)