export default ({ breakpoints }) => ({
    league: {},
    list: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',

        '& .Fixture': {
            width: 'calc(100% - 4em)',

            [breakpoints.sm]: {
                width: 'calc(50% - 4em)',
            },

            [breakpoints.lg]: {
                width: 'calc(33.333% - 4em)',
            },
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80em',
        maxWidth: 'calc(100% - 2em)',
        margin: '0 auto',
        padding: '1em',

        [breakpoints.sm]: {
            flexDirection: 'row',
            maxWidth: 'calc(100% - 3em)',
            padding: '2em 1.5em',
        },
    },
    introduction: {
        flexGrow: 0,
        flexShrink: 0,
        paddingRight: '1em',

        [breakpoints.sm]: {
            width: '25%',
        },
    },
    content: {
        width: '100%',

        [breakpoints.sm]: {
            width: '75%',
        },
    },
    title: {
        color: '#444',
        fontSize: '2em',
        marginBottom: '0.25em',
    },
    body: {
        color: '#777',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: '12px',

        '& thead': {
            backgroundColor: '#aaa'
        },

        '& thead th':{
            fontWeight: '800',
            color: '#fff'
        },

        '& tbody tr:nth-child(2n)':{
            backgroundColor: '#e5e5e5'
        },

        '& th, td':{
            padding: '8px',
            fontWeight: '400'
        }
    },
    caton: {
        backgroundColor: '#218CC7 !important',

        '& td': {
            fontWeight: '700',
            color: '#fff'
        }
    }
})