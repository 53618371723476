import React from 'react'
import withStyles from 'react-jss'
import styles from './Footer.styles'
import PropTypes from 'prop-types'

const Footer = ({ classes }) => <footer className={classes.footer}>
    <div className={classes.container}>
        <div className={classes.sponsors}>
            <div className={classes.introduction}>
                <h2 id="sponsors" className={classes.title}>Sponsors</h2>
                <p className={classes.body}>
                    We’re lucky enough to have the support of some incredible local sponsors. We’d like to thank them
                    for their involvement with the Sports Association.
                </p>
            </div>
            <div className={classes.content}>
                <ul className={classes.links}>
                    <li className={classes.link}>
                        <a href="https://www.forterra.co.uk" target="_blank" rel="noreferrer">
                            <div className={classes.sponsorName}>Forterra</div>
                            <div className={classes.sponsorCategory}>Building Materials</div>
                        </a>

                    </li>
                    <li className={classes.link}>
                        <a href="https://www.ibayhomes.com/" target="_blank" rel="noreferrer">
                            <div className={classes.sponsorName}>iBay Homes</div>
                            <div className={classes.sponsorCategory}>Estate Agent</div>
                        </a>

                    </li>
                    <li className={classes.link}>
                        <a href="https://stationhotelcaton.co.uk/" target="_blank" rel="noreferrer">
                            <div className={classes.sponsorName}>Station Hotel Caton</div>
                            <div className={classes.sponsorCategory}>Pub</div>
                        </a>
                    </li>
                    <li className={classes.link}>
                        <a href="https://mhmotorsmorecambe.co.uk/" target="_blank" rel="noreferrer">
                            <div className={classes.sponsorName}>M&H Motors Morecambe</div>
                            <div className={classes.sponsorCategory}>Mechanics</div>
                        </a>
                    </li>
                    <li className={classes.link}>
                        <a href="https://www.facebook.com/Allen-Myers-ltd-scrap-metal-merchants-1419053218109287/" target="_blank" rel="noreferrer">
                            <div className={classes.sponsorName}>Allen & Myers</div>
                            <div className={classes.sponsorCategory}>Scrap Metal Merchants</div>
                        </a>
                    </li>
                    <li className={classes.link}>
                        <a href="https://www.stationgaragecaton.co.uk/" target="_blank" rel="noreferrer">
                            <div className={classes.sponsorName}>Station Garage Caton</div>
                            <div className={classes.sponsorCategory}>Garage and Sales</div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div className={classes.end}>
        <div className={classes.container}>
            <span className={classes.copyright}>&copy; Caton Sports Association</span>
        </div>
    </div>
</footer>

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)
