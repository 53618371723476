import React, {useEffect} from 'react';

const Page = ({title, component}) => {
    // Set the page title on mounting.
    useEffect(() => {
        document.title = `${title} | Caton Sports Association`
    }, [title]);

    // Render the page.
    const PageComponent = component
    return <><PageComponent/></>
};

export default Page;