import React, { useEffect } from 'react'
import { useParams, Redirect, Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Navigation } from '../../components'
import withStyles from 'react-jss'
import styles from './Article.styles'
import format from 'date-fns/format'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share'

const Article = ({ classes }) => {
    const { id } = useParams()

    const { data, loading } = useQuery(gql`
        query GetArticle($id: ID) {
            article(where: {id: $id}) {
                id
                title
                content {
                    html
                }
                updatedAt
                publishedAt
            }
        }
    `, { variables: { id } })

    useEffect(() => {
        if (data?.article) {
            document.title = `${data.article.title} | Caton Sports Association`
        }
    }, [data])

    if (!loading && !data.article) {
        return <Redirect to="/notfound" />
    }

    return <>
        <Navigation />
        <div className={classes.container}>
            <div>
                <Link className={classes.back} to="/">&larr; Return to Home</Link>
            </div>
            <article>
                {loading ? <div className={classes.loader}>
                    <Loader type="ThreeDots" color="#1C212D" height={100} width={100} />
                    Loading Article
                </div> : <>
                    <div className={classes.titles}>
                        <h1 className={classes.title}>{data.article.title}</h1>
                        <h2 className={classes.date}>Published {format(new Date(data.article.publishedAt), 'do MMMM yyyy')}</h2>
                        <h2 className={classes.date}>Last
                            Updated {format(new Date(data.article.updatedAt), 'do MMMM yyyy')}</h2>
                        <div className={classes.socials}>
                            <EmailShareButton
                                url={`https://catonsports.co.uk/articles/${id}`}
                                title={data.article.title}
                            ><EmailIcon
                                size={30} /></EmailShareButton>
                            <FacebookShareButton
                                url={`https://catonsports.co.uk/articles/${id}`}
                                title={data.article.title}
                            ><FacebookIcon
                                size={30} /></FacebookShareButton>
                            <LinkedinShareButton
                                url={`https://catonsports.co.uk/articles/${id}`}
                                title={data.article.title}
                            ><LinkedinIcon
                                size={30} /></LinkedinShareButton>
                            <TwitterShareButton
                                url={`https://catonsports.co.uk/articles/${id}`}
                                title={data.article.title}
                            ><TwitterIcon
                                size={30} /></TwitterShareButton>
                            <WhatsappShareButton
                                url={`https://catonsports.co.uk/articles/${id}`}
                                title={data.article.title}
                            ><WhatsappIcon
                                size={30} /></WhatsappShareButton>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: data.article.content.html }} />
                </>}
            </article>
        </div>
    </>
}

export default withStyles(styles)(Article)